import React from "react";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { windowExists, isDevelopmentMode } from "@internal/utils-browser";

// try to use culture resolution to send the user to the correct index page
// this is not accessible in production, it's only used for development
export default function DevOnlyNotFoundPage() {
    if (windowExists()) {
        if (!isDevelopmentMode()) {
            newRelicWrapper.addPageAction("studio-404");
        }
        // if our hostname contains vistaprint, then we want our base to be /studio, not /studio/five/{locale}
        // the latter is only true on localhost and hitting cloudfront directly
        // the first case is a bit tricky though, as it could be /studio/fr.
        // i think for now, given that belgium is our only multi-locale, i don't care
        if (/vistaprint/.test(window.location.hostname)) {
            window.location.href = `/studio/${window.location.search}`;
        } else {
            // just a best guess
            const queryParams = window.location.search || "?mpvId=standardBusinessCards";
            window.location.href = `/studio/six/en-ie/${queryParams}`;
        }
    }

    return <div></div>;
}

DevOnlyNotFoundPage.displayName = "DevOnlyNotFoundPage";
